<template>
    <div>
        <h2>จัดการกลุ่มสิทธิ์</h2>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-btn              
              @click="deleteMultiple()"
              :disabled="datatable.selectedItems.length == 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>                    
            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'role_create', params: { role_id: 'create' } })">เพิ่มกลุ่มสิทธิ์</v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/employee/role/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">            
            <v-simple-checkbox
              v-model="isSelected"
              color="primary"
              @input="select"
              :ripple="false"
            />
          </template>

          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-btn
              icon
              @click="$router.push({name: 'role_create', params: { role_id: item.id }})"
            ><v-icon>{{ $store.getters['auth/GET_ABILITY'].can('update', 'Employee') ? mdiPlaylistEdit : mdiEyeOutline }}</v-icon></v-btn>
            <v-btn
              icon
              @click="deleteSingle(item.id, item.name)"
              :disabled="!$store.getters['auth/GET_ABILITY'].can('delete', 'Employee') || item.admins_count > 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>            
          </template>
          </delay-datatable>
        </v-card>        
    </div>
</template>
<script>
import { asyncDelete } from '@/helpers/asyncAxios'
import { mdiDeleteOutline, mdiEyeOutline, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'ชื่อแอดมิน',
            value: 'name',
          },
          {
            text: 'จำนวนแอดมิน',
            value: 'admins_count',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiDeleteOutline,
      mdiPlaylistEdit,
      mdiEyeOutline,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        search: this.datatable.search,
      }
    },
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async deleteMultiple() {
      const roleNames = this.datatable.selectedItems.map(item => item.name).join(', ')
      const roleIds = this.datatable.selectedItems.map(item => item.id)

      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + roleNames + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/employee/role', { items: roleIds })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/employee/role', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
  },
}
</script>